function RightPanel() {
    return (
        <div className="kt-grid kt-grid--ver kt-grid--root">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content auth-pages-right-section">
                <div className="kt-login__section">
                    <div className="kt-login__block">
                        <h3 className="kt-login__title">Join Almond’s Community</h3>
                        <div className="kt-login__desc">
                            Almond’s assessment helps lenders approve more loans for more
                            <br /> people at lower costs.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RightPanel;
