import toastr from 'toastr';

function showError(error) {
    toastr.error(error, '', { closeButton: 'true', progressBar: true });
}

function showSuccess(message) {
    toastr.success(message, '', { closeButton: 'true', progressBar: true });
}

export { showError, showSuccess };
