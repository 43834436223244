import csrfToken from '../../utils/csrfUtils';
import { showError, showSuccess } from '../../utils/toastrUtils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const nav = useNavigate();
    const [email, setEmail] = useState('');

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const submitForm = async (event) => {
        event.preventDefault();

        fetch(`/v1/users/new-password`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'X-XSRF-TOKEN': csrfToken()
            },
            body: JSON.stringify({
                email: email
            })
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json();
                } else {
                    showSuccess('You will receive a password reset link at your email address in few minutes.');
                    nav('/users/login');
                }
            })
            .then((response) => {
                if (response && response.detail) {
                    showError(response.detail);
                }
            });
    };

    return (
        <div className="kt-login__signin">
            <div className="kt-login__head">
                <h3 className="kt-login__title">Forgot your password?</h3>
            </div>
            <div className="kt-login__form">
                <form className="kt-form" data-parsley-validate="true" acceptCharset="UTF-8" onSubmit={submitForm}>
                    <div className="form-group">
                        <input
                            autoFocus="autofocus"
                            autoComplete="email"
                            className="form-control"
                            placeholder="Email"
                            required
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="kt-login__actions">
                        <input
                            type="submit"
                            value="Send Reset Password Instructions"
                            className="btn btn-brand btn-pill btn-elevate"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
