import { showError } from '../../utils/toastrUtils';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function Login() {
    const [searchParams, setSearchParams] = useSearchParams();
    const error = searchParams.get('error');

    useEffect(() => {
        if (!!error) {
            searchParams.delete('error');
            setSearchParams(searchParams);
            showError('Username or password is incorrect. Please provide valid username or password.');
        }
    }, [error]);

    return (
        <div className="kt-login__signin">
            <div className="kt-login__head">
                <h3 className="kt-login__title">Sign in to Admin</h3>
            </div>
            <div className="kt-login__form">
                <form className="kt-form" id="new_user" acceptCharset="UTF8" action="/login" method="POST">
                    <div className="form-group">
                        <input
                            autoFocus="autofocus"
                            className="form-control"
                            placeholder="Email"
                            required
                            type="email"
                            name="username"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            autoComplete="password"
                            className="form-control"
                            placeholder="Password"
                            required
                            type="password"
                            name="password"
                        />
                    </div>
                    <div className="kt-login__extra">
                        <label className="kt-checkbox">
                            <input type="checkbox" name="remember" />
                            Remember Me
                            <span></span>
                        </label>
                        <a className="kt-link kt-login__link-forgot" href="/users/forgot-password">
                            Forgot your password?
                        </a>
                    </div>
                    <div className="kt-login__actions">
                        <input
                            type="submit"
                            value="Log in"
                            className="btn btn-brand btn-pill btn-elevate"
                            data-disable-with="Log in"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
