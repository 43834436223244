import './App.css';
import SiteLogo from './assets/images/site_logo.jpg';
import RightPanel from './layouts/RightPanel';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
    return (
        <BrowserRouter>
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div
                    className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                    id="kt_login"
                >
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <a href="/">
                                                <img className="almonds_logo" src={SiteLogo} />
                                            </a>
                                        </div>
                                        <Routes>
                                            <Route path="/users/login" element={<Login />} />
                                            <Route path="/users/forgot-password" element={<ForgotPassword />} />
                                            <Route path="/users/reset-password" element={<ResetPassword />} />
                                            <Route path="*" element={<Navigate to="/users/login" replace />} />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RightPanel />
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
