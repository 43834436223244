import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import csrfToken from '../../utils/csrfUtils';
import { showError, showSuccess } from '../../utils/toastrUtils';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const nav = useNavigate();
    const resetPasswordToken = new URLSearchParams(useLocation().search).get('resetPasswordToken');
    const [formError, setFormError] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const handleChange = (event) => {
        if (event.target.name === 'password') {
            setPassword(event.target.value);
            if (confirmPassword && event.target.value && confirmPassword !== event.target.value) {
                setFormError({ message: 'Passwords do not match.' });
            } else {
                setFormError(null);
            }
        } else {
            setConfirmPassword(event.target.value);
            if (password && event.target.value && password !== event.target.value) {
                setFormError({ message: 'Passwords do not match.' });
            } else {
                setFormError(null);
            }
        }
    };

    const submitForm = async (event) => {
        event.preventDefault();
        setFormError(null);

        fetch(`/v1/users/reset-password`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'X-XSRF-TOKEN': csrfToken()
            },
            body: JSON.stringify({
                token: resetPasswordToken,
                password: password
            })
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json();
                } else {
                    showSuccess('Your Password has been updated successfully.');
                    nav('/users/login');
                }
            })
            .then((response) => {
                if (response && response.detail) {
                    showError(response.detail);
                }
            });
    };

    return (
        <div className="kt-login__signin">
            <div className="kt-login__head">
                <h3 className="kt-login__title">Reset your password</h3>
            </div>
            <div className="kt-login__form">
                {formError && <ErrorMessage error={formError.message} />}
                <form className="kt-form" data-parsley-validate="true" acceptCharset="UTF-8" onSubmit={submitForm}>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            autoFocus="autofocus"
                            placeholder="New Password"
                            name="password"
                            required
                            minLength={6}
                            maxLength={128}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <div className="kt-login__actions">
                        <input
                            type="submit"
                            value="Update Password"
                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                        />
                    </div>
                    <span>Already have login and password?</span>
                    <a className="kt-link kt-login__link-forgot" href="/users/login">
                        Login
                    </a>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;
